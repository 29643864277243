import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import {
  AppBar, Box, IconButton, MenuItem, Modal, Toolbar, Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import { useSelector } from 'react-redux';
import { redirectAndScroll } from '../lib/utils';
import { modalStyle } from '../styles';
import SearchBar from './SearchBar';
import Login from './Login';
import BrowseBar from './BrowseBar';
// import BrowseBar from './BrowseBar';

function NavBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, , removeCookie] = useCookies(['cookie-name']);
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const doRedirect = (e, url) => {
    e.preventDefault();
    redirectAndScroll(navigate, url);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const goHome = () => {
    redirectAndScroll(navigate, '');
  };

  const logOut = () => {
    removeCookie('jwt', { path: '/' });
    window.location = '/';
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Box>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => goHome()}
          >
            <DinnerDiningIcon />
          </IconButton>
          <SearchBar formAction="dish" placeholderText="Dish Search" />
          <SearchBar formAction="ingredient" placeholderText="Ingredient Search" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <MenuItem onClick={(e) => { doRedirect(e, '/'); }}>Home</MenuItem>
            <MenuItem onClick={(e) => { doRedirect(e, '/origins'); }}>Origins</MenuItem>
            <MenuItem onClick={(e) => { doRedirect(e, '/dish/made'); }}>Made</MenuItem>
            { user?.admin && <MenuItem onClick={(e) => { doRedirect(e, '/dish/create'); }}>Create Dish</MenuItem>}
            { user?.admin && <MenuItem onClick={(e) => { doRedirect(e, '/origin/create'); }}>Create Origin</MenuItem>}
            { user?.id
              ? <MenuItem onClick={() => { logOut(); }}>Log Out</MenuItem>
              : <MenuItem onClick={() => { setShowModal(true); }}>Log In</MenuItem>}
          </Box>
        </Toolbar>
        <Modal
          open={showModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5">No need to create an account just click the login link to login via Gmail.</Typography>
            <Login modalClose={handleModalClose} />
          </Box>
        </Modal>
      </AppBar>
      <BrowseBar open={isDrawerOpen} toggleDrawer={toggleDrawer} setShowLoginModal={setShowModal} />
    </Box>
  );
}

export default NavBar;
