import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

function PageControls(props) {
  const {
    setPage,
  } = props;
  const dishInfo = useSelector((state) => state.dishes.dishInfo);
  const currentPage = useSelector((state) => state.dishes.currentPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination count={dishInfo.maxPageNum} page={currentPage} onChange={handleChange} variant="outlined" color="primary" />
      {/* {dishInfo.total}
      {' '}
      total */}
    </Stack>
  );
}

PageControls.propTypes = {
  setPage: PropTypes.func.isRequired,
};

PageControls.defaultProps = {

};

export default PageControls;
