import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setDishInfo, setIngredientInfo } from '../actions';
import { fetchDishSearchResults, fetchIngredientSearchResults } from '../lib/api';
import { redirectAndScroll } from '../lib/utils';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
function SearchBar(props) {
  const { formAction, placeholderText } = props;
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const doDishSearch = async (page = 1) => {
    const payload = await fetchDishSearchResults(query, page);
    dispatch(setDishInfo(payload));
  };

  const doIngredientSearch = async (page = 1) => {
    const payload = await fetchIngredientSearchResults(query, page);
    dispatch(setIngredientInfo(payload));
  };

  const doSubmit = (e) => {
    e.preventDefault();
    if (formAction === 'dish') {
      doDishSearch();
    } else if (formAction === 'ingredient') {
      doIngredientSearch();
    }
    redirectAndScroll(navigate, `/search/${formAction}?q=${query}`);
  };

  return (
    <form onSubmit={(e) => doSubmit(e)}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          type="text"
          placeholder={placeholderText}
          name="query"
          value={query}
          inputProps={{ 'aria-label': 'search' }}
          onInput={(e) => { setQuery(e.target.value); }}
        />
      </Search>
    </form>
  );
}
SearchBar.propTypes = {
  formAction: PropTypes.string,
  placeholderText: PropTypes.string,
};

SearchBar.defaultProps = {
  formAction: '',
  placeholderText: '',
};
export default SearchBar;
