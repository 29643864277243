export const setDishInfo = (dishInfo) => ({
  type: 'SET_DISH_INFO',
  dishInfo,
});

export const setActiveDish = (activeDish) => ({
  type: 'SET_ACTIVE_DISH',
  activeDish,
});

export const setCurrentDishesPage = (currentPage) => ({
  type: 'SET_CURRENT_DISHES_PAGE',
  currentPage,
});

export const setDishList = (dishList) => ({
  type: 'SET_DISH_LIST',
  dishList,
});

export const setCurrentIngredientsPage = (currentPage) => ({
  type: 'SET_CURRENT_DISHES_PAGE',
  currentPage,
});

export const setOriginInfo = (originInfo) => ({
  type: 'SET_ORIGIN_INFO',
  originInfo,
});

export const setCurrentIngredient = (currentIngredient) => ({
  type: 'SET_CURRENT_INGREDIENT',
  currentIngredient,
});

export const setIngredientInfo = (ingredientInfo) => ({
  type: 'SET_INGREDIENT_INFO',
  ingredientInfo,
});

export const setShowIngredients = (showIngredients) => ({
  type: 'SET_SHOW_INGREDIENTS',
  showIngredients,
});

export const setShowSteps = (showSteps) => ({
  type: 'SET_SHOW_STEPS',
  showSteps,
});

export const setOrigins = (origins) => ({
  type: 'SET_ORIGINS',
  origins,
});

export const setUser = (user) => ({
  type: 'SET_USER',
  user,
});
