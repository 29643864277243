import React, { useEffect } from 'react';
import {
  Container, Paper, Typography, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import { setOriginInfo } from '../actions';
import { paperStyle } from '../styles';
import { fetchOriginInfo } from '../lib/api';
import { getCountry } from '../lib/countries';
import { redirectAndScroll } from '../lib/utils';
// import Dish from './Dish';

function Origin() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const originInfo = useSelector((state) => state.origins.originInfo);
  const origin = originInfo.origins;

  const getOrigin = async () => {
    const payload = await fetchOriginInfo(id);
    payload.origins.dishes = payload.origins.dishes.sort((a, b) => a.name.localeCompare(b.name));
    dispatch(setOriginInfo(payload));
  };

  useEffect(() => {
    getOrigin();
  }, []);

  const doRedirect = (e, url) => {
    e.preventDefault();
    redirectAndScroll(navigate, url);
  };

  return (
    <Container>
      <Typography variant="h3">
        {origin?.name}
        {' '}
        { (getCountry(origin?.code))?.emoji}
      </Typography>
      <Paper style={paperStyle}>

        <Typography variant="h4">Dishes</Typography>
        <ul>
          { origin?.dishes.map((dish) => (
            <li key={`dish-${dish.id}-${dish.name}`}>
              <Button variant="text" onClick={(e) => doRedirect(e, `/dish/${dish.id}`)}>
                {dish.name}
              </Button>
              {dish.have_made ? '✓' : '✗'}
            </li>
            // <Dish dish={dish} key={`dish-${dish.id}-${dish.name}`} />
          ))}
        </ul>
      </Paper>
    </Container>

  );
}

export default Origin;
