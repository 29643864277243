import React, { useEffect, useState } from 'react';
import {
  Box, Button, Container, Paper, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { paperStyle } from '../styles';
import { fetchMadeDishes, fetchNotMadeDishes } from '../lib/api';
import { setDishList } from '../actions';
import { redirectAndScroll } from '../lib/utils';

function MadeDish() {
  const [madeList, setMadeList] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dishList = useSelector((state) => state.dishes.dishList);

  const getMadeOrNotMade = async () => {
    let payload;
    if (madeList) {
      payload = await fetchMadeDishes();
    } else {
      payload = await fetchNotMadeDishes();
    }
    dispatch(setDishList(payload.dish));
  };

  const handleButton = () => {
    setMadeList(!madeList);
    getMadeOrNotMade();
  };

  const handleClick = (id) => {
    redirectAndScroll(navigate, `/dish/${id}`);
  };

  useEffect(() => {
    getMadeOrNotMade();
  }, []);

  return (
    <Container>
      <Typography variant="h2">Made List</Typography>
      <Paper style={paperStyle}>
        <Box>
          <Button onClick={() => handleButton()}>
            Switch
          </Button>
        </Box>
        <ol>
          {dishList?.map((dish) => (
            <li key={`dish-list-id-${dish.id}`}><Button onClick={() => handleClick(dish.id)}>{dish.name}</Button></li>
          ))}
        </ol>
      </Paper>
    </Container>
  );
}

export default MadeDish;
