import { IconButton } from '@mui/material';
import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { scrollToTop } from '../lib/utils';
import { toTop } from '../styles';

function ToTopButton() {
  return (
    <IconButton color="primary" sx={toTop} onClick={() => scrollToTop()}><KeyboardArrowUpIcon /></IconButton>
  );
}

export default ToTopButton;
