import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, Button } from '@mui/material';
import { setShowIngredients, setShowSteps } from '../actions';

function HideShow() {
  const dispatch = useDispatch();
  const showIngredients = useSelector((state) => state.dishes.showIngredients);
  const showSteps = useSelector((state) => state.dishes.showSteps);
  const changeIngredientDisplay = () => {
    dispatch(setShowIngredients(!showIngredients));
  };

  const changeStepDisplay = () => {
    dispatch(setShowSteps(!showSteps));
  };
  return (
    <Toolbar>
      <Button variant="contained" role="menuitem" onClick={() => changeIngredientDisplay()}>
        Turn
        {' '}
        {showIngredients ? 'off' : 'on'}
        {' '}
        Ingredients
      </Button>
      <Button variant="contained" role="menuitem" onClick={() => changeStepDisplay()} sx={{ marginLeft: '5px' }}>
        Turn
        {' '}
        {showSteps ? 'off' : 'on'}
        {' '}
        Steps
      </Button>
    </Toolbar>
  );
}

export default HideShow;
