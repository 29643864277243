import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Box, Button, IconButton, Modal, Paper, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { paperStyle, modalStyle } from '../styles';
import { redirectAndScroll, urlDomainGrabber } from '../lib/utils';
import FlagEmojiButton from './FlagEmojiButton';
import { deleteDish } from '../lib/api';

function Dish(props) {
  const { dish } = props;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const showIngredients = useSelector((state) => state.dishes.showIngredients);
  const showSteps = useSelector((state) => state.dishes.showSteps);
  const user = useSelector((state) => state.users.user);
  const handleModalClose = () => {
    setShowModal(false);
  };

  const originAction = (origin) => {
    const url = `/origin/${origin.id}`;
    redirectAndScroll(navigate, url);
  };

  const doRedirect = (e, url) => {
    e.preventDefault();
    redirectAndScroll(navigate, url);
  };

  const doDelete = async () => {
    const response = await deleteDish(dish.id);
    if (response) redirectAndScroll(navigate, '/');
  };

  const stepRenderer = (steps) => {
    const stepArr = steps.split(/\r|\\r/).filter((item) => item);
    return (
      <ol>
        {stepArr.map((step, idx) => (<li key={`step-${idx}`}>{step}</li>))}
      </ol>
    );
  };

  const relatedDishes = () => dish?.main_dishes?.map((related, idx) => (
    <Button
      key={`dish-${dish.id}-related-dishes-${related.the_related_dish_id}-${idx}`}
      onClick={() => redirectAndScroll(navigate, `/dish/${related.the_related_dish_id}`)}
    >
      {related.related_dish_name}
    </Button>
  ));

  return (
    <Paper style={paperStyle}>
      <Typography variant="h3">
        {dish.name}
        {' '}
        {dish.have_made ? '✓' : '✗'}
        {' '}
        { dish.origins?.map((origin) => (
          <FlagEmojiButton key={`dish-id-${dish.id}-origin-id-${origin.id}`} origin={origin} action={originAction} />
        ))}
      </Typography>
      {user?.admin && (
        <Box>
          <IconButton type="button" onClick={(e) => doRedirect(e, `/dish/edit/${dish.id}`)}><EditIcon /></IconButton>
          <IconButton type="button" onClick={() => setShowModal(true)}><DeleteForeverIcon /></IconButton>
        </Box>
      )}
      <Typography variant="h5">
        Source:
        {' '}
        { dish.source?.startsWith('http')
          ? <a href={dish.source} target="_blank" rel="noreferrer">{urlDomainGrabber(dish.source)}</a>
          : dish.source}
      </Typography>
      <Typography variant="h4">Ingredients</Typography>
      <ul>
        {showIngredients && dish.dish_ingredients?.map((di) => (
          <li key={`dish-${dish.id}-${dish.name}-${di.id}`}>
            { di.ingredient_id === 1
              ? <span>{di.description}</span>
              : (
                <Button style={{ textAlign: 'left', textDecorationStyle: 'dotted' }} variant="text" className="no-button" onClick={(e) => doRedirect(e, `/ingredients/${di.ingredient_id}`)}>
                  {di.description.replace(/[\r\n\t]/g, '')}
                </Button>
              )}
          </li>
        ))}
      </ul>
      {/* TODO improve this area */}
      <div className="steps">
        <Typography variant="h4">Steps</Typography>
        {showSteps && stepRenderer(dish.steps)}
      </div>
      <div className="notes">
        <Typography variant="h4">Notes</Typography>
        {dish.notes && stepRenderer(dish.notes)}
      </div>
      <div className="related-dishes">
        <Typography variant="h4">Related Dishes</Typography>
        {relatedDishes()}
      </div>
      <Modal
        open={showModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          Do you really want to delete?
          <Button variant="contained" onClick={() => doDelete(dish.id)}>Yes</Button>
          <Button variant="contained" color="error" onClick={handleModalClose}>No</Button>
        </Box>
      </Modal>
    </Paper>

  );
}

Dish.propTypes = {
  dish: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    have_made: PropTypes.bool,
    source: PropTypes.string,
    steps: PropTypes.string,
    notes: PropTypes.string,
    origins: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      name: PropTypes.string,
      origin_type: PropTypes.string,
    })),
    dish_ingredients: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    })),
    main_dishes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      main_dish_id: PropTypes.number,
      the_related_dish_id: PropTypes.number,
      related_dish_name: PropTypes.string,
    })),
    the_related_dishes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      main_dish_id: PropTypes.number,
      the_related_dish_id: PropTypes.number,
      related_dish_name: PropTypes.string,
    })),
  }),
};

Dish.defaultProps = {
  dish: {
    id: 1,
    name: '',
    have_made: '',
    source: '',
    steps: '',
    notes: '',
    origins: PropTypes.arrayOf({
      id: 1,
      code: '',
      name: '',
      origin_type: '',
    }),
    dish_ingredients: PropTypes.arrayOf({
      id: 1,
      description: '',
    }),

  },
};

export default Dish;
