import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Alert,
  Box,
  Button, Checkbox, Container, FormControlLabel, Paper, Snackbar, TextField, Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { createDish, fetchIngredientSearchResults, fetchOriginSearchResults } from '../lib/api';
import { redirectAndScroll } from '../lib/utils';
import { formControlLabelStyle, formDividerStyle, paperStyle } from '../styles';

function CreateDish() {
  const [formData, setFormData] = useState({
    name: '',
    haveMade: false,
    source: '',
    alternateName: '',
    steps: '',
    notes: '',
    origins: [{
      origin_id: 1,
      description: '',
    }],
    ingredients: [{
      ingredient_id: 1,
      description: '',
    }],
    currentActiveIngredient: 0,
    currentActiveOrigin: 0,
    possibleIngredientList: [],
    possibleOriginList: [],
  });
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const navigate = useNavigate();

  const addIngredientButton = () => {
    const newIngredientList = formData.ingredients;
    newIngredientList.push({
      ingredient_id: 1,
      description: '',
    });
    setFormData({ ...formData, ingredients: newIngredientList });
  };

  const removeIngredientButton = (id) => {
    const newIngredientList = formData.ingredients;
    newIngredientList.splice(id, 1);
    setFormData({ ...formData, ingredients: newIngredientList });
  };

  const addOriginButton = () => {
    const newOriginList = formData.origins;
    newOriginList.push({
      origin_id: 1,
      name: '',
    });
    setFormData({ ...formData, origins: newOriginList });
  };

  const removeOriginButton = (id) => {
    const newOriginList = formData.origins;
    newOriginList.splice(id, 1);
    setFormData({ ...formData, origins: newOriginList });
  };

  const doIngredientSearch = async (query) => {
    const payload = await fetchIngredientSearchResults(query);
    setFormData({ ...formData, possibleIngredientList: payload.ingredients });
  };

  const doOriginSearch = async (query) => {
    const payload = await fetchOriginSearchResults(query);
    setFormData({ ...formData, possibleOriginList: payload.origins });
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckBox = (e) => {
    setFormData({ ...formData, haveMade: e.target.checked });
  };

  const handleChangeInList = (e, idx) => {
    const currentArrData = formData.ingredients;
    const currentObj = currentArrData[idx];
    currentObj[e.target.name] = e.target.value;
    currentArrData[idx] = currentObj;
    if (e.target.name === 'name' && e.target.value.length > 3) doIngredientSearch(e.target.value);
    setFormData({ ...formData, currentActiveIngredient: idx, ingredients: currentArrData });
  };

  const handleChangeInOriginList = (e, idx) => {
    const currentArrData = formData.origins;
    const currentObj = currentArrData[idx];
    currentObj[e.target.name] = e.target.value;
    currentArrData[idx] = currentObj;
    if (e.target.name === 'description' && e.target.value.length > 3) doOriginSearch(e.target.value);
    setFormData({ ...formData, currentActiveOrigin: idx, origins: currentArrData });
  };

  const prepareFormForSubmission = async () => {
    const payload = {
      name: formData.name,
      source: formData.source,
      steps: formData.steps,
      haveMade: formData.haveMade,
      dish_ingredients: formData.ingredients,
      dish_origins: formData.origins,
    };
    try {
      const result = await createDish(payload);
      if (result?.id) {
        redirectAndScroll(navigate, `/dish/${result.id}`);
      }
    } catch (e) {
      setSnackMessage(e.message);
      setSnackOpen(true);
    }
  };

  const chooseIngredientSuggestion = (idx, ing) => {
    const currentArrData = formData.ingredients;
    const currentObj = currentArrData[idx];
    currentObj.ingredient_id = ing.id;
    currentObj.name = ing.name;
    currentArrData[idx] = currentObj;
    setFormData({ ...formData, currentActiveIngredient: idx, ingredients: currentArrData });
  };

  const chooseOriginSuggestion = (idx, origin) => {
    const currentArrData = formData.origins;
    const currentObj = currentArrData[idx];
    currentObj.origin_id = origin.id;
    currentObj.description = origin.name;
    currentArrData[idx] = currentObj;
    setFormData({ ...formData, currentActiveOrigin: idx, origins: currentArrData });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    prepareFormForSubmission();
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  return (
    <Container>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">{snackMessage}</Alert>
      </Snackbar>
      <Typography variant="h3">Create Dish</Typography>
      <Paper style={paperStyle}>
        <Box style={formDividerStyle}>
          <Typography variant="h3">Dish Info</Typography>
          <Box style={formDividerStyle}>
            <TextField label="Name" onChange={(e) => handleOnChange(e)} value={formData.name} type="text" name="name" id="create-dish-name" />
            <FormControlLabel style={formControlLabelStyle} onClick={(e) => handleCheckBox(e)} control={<Checkbox checked={formData.haveMade} />} label="Have made?" />
          </Box>
          <Box style={formDividerStyle}>
            <TextField label="Source" onChange={(e) => handleOnChange(e)} value={formData.source} type="text" name="source" id="create-dish-source" />
          </Box>
          <Box style={formDividerStyle}>
            <TextField label="Alternate Name" onChange={(e) => handleOnChange(e)} value={formData.alternateName} type="text" name="alternateName" id="create-dish-alternate-name" />
          </Box>
        </Box>
        <Box style={formDividerStyle}>
          <Typography variant="h3">Origins</Typography>
          { formData.origins.map((origin, idx) => (
            <Box style={formDividerStyle} key={`new-origin-${idx}`}>
              <input type="hidden" onChange={(e) => handleChangeInOriginList(e, idx)} value={origin.id} name="id" id={`id-${idx}`} />
              <TextField label="Origin" onChange={(e) => handleChangeInOriginList(e, idx)} value={origin.description} type="text" name="description" id={`description-${idx}`} />
              <IconButton variant="text" onClick={() => removeOriginButton(idx)}><RemoveCircleOutlineIcon /></IconButton>
              {formData.currentActiveOrigin === idx
                && (
                <div>
                  {formData.possibleOriginList.map((possibleOrigin, id) => (
                    <Button key={`origin-suggestion-${id}`} variant="text" className="no-button" onClick={() => chooseOriginSuggestion(idx, possibleOrigin)}>
                      <span className={possibleOrigin.id === origin.origin_id ? 'bold-selected' : ''}>
                        {possibleOrigin.name}
                      </span>
                    </Button>
                  ))}
                </div>
                )}
            </Box>
          ))}
          <Button variant="text" onClick={() => addOriginButton()}>
            + Add Origin
          </Button>
        </Box>
        <Box style={formDividerStyle}>
          <Typography variant="h3">Ingredients</Typography>
          { formData.ingredients.map((ingredient, idx) => (
            <Box style={formDividerStyle} key={`new-ingredient-${idx}`}>
              <input type="hidden" onChange={(e) => handleChangeInList(e, idx)} value={ingredient.id} name="id" id={`id-${idx}`} />
              <TextField label="Ingredient" onChange={(e) => handleChangeInList(e, idx)} value={ingredient.description} type="text" name="description" id={`description-${idx}`} />
              <TextField label="Database Name" onChange={(e) => handleChangeInList(e, idx)} value={ingredient.name} type="text" name="name" id={`name-${idx}`} />
              <IconButton type="button" onClick={() => removeIngredientButton(idx)}><RemoveCircleOutlineIcon /></IconButton>
              {formData.currentActiveIngredient === idx
                  && (
                  <div>
                    {formData.possibleIngredientList.map((ing) => (
                      <Button variant="text" onClick={() => chooseIngredientSuggestion(idx, ing, ingredient)}>

                        <span className={ing.id === ingredient.ingredient_id ? 'bold-selected' : ''}>
                          {ing.name}
                        </span>
                      </Button>
                    ))}
                  </div>
                  )}
            </Box>
          ))}
          <Button variant="text" onClick={() => addIngredientButton()}>
            + Add Ingredient
          </Button>
        </Box>
        <Box style={formDividerStyle}>
          <Typography variant="h3">Steps</Typography>
          <Box style={formDividerStyle}>
            <TextField label="Steps" multiline style={{ width: '90%' }} rows={8} onChange={(e) => handleOnChange(e)} value={formData.steps} name="steps" id="create-dish-steps" />
          </Box>
        </Box>
        <Box style={formDividerStyle}>
          <Typography variant="h3">Notes</Typography>
          <Box style={formDividerStyle}>
            <TextField label="Notes" multiline style={{ width: '90%' }} rows={8} onChange={(e) => handleOnChange(e)} value={formData.notes} name="notes" id="create-dish-notes" />
          </Box>
        </Box>
        <Box style={formDividerStyle}>
          <Button variant="contained" onClick={(e) => handleFormSubmit(e)}>Submit</Button>
        </Box>
      </Paper>
    </Container>

  );
}
export default CreateDish;
