/* eslint-disable no-case-declarations */
const users = (
  state = {
    user: {
      id: 0,
      email: '',
      family_name: '',
      given_name: '',
      name: '',
      admin: false,
      sub: '',
    },
  },
  action = {},
) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user };
    default:
      return state;
  }
};

export default users;
