const deDupe = (arr) => arr.filter((v, i, a) => a.findIndex((v2) => (v2.id === v.id)) === i);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const redirectAndScroll = (navigate, url) => {
  navigate(url);
  scrollToTop();
};

const urlDomainGrabber = (url) => (new URL(url)).hostname;

export {
  deDupe,
  scrollToTop,
  redirectAndScroll,
  urlDomainGrabber,
};
