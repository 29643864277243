/* eslint-disable no-case-declarations */
const origins = (
  state = {
    originInfo: {
      origin: {},
      total: 0,
      maxPageNum: 0,
      perPage: 25,
    },
    origins: {
      origins: [],
      total: 0,
      maxPageNum: 0,
      perPage: 100,
    },
    // currentPage: 1,
  },
  action = {},
) => {
  switch (action.type) {
    case 'SET_ORIGIN_INFO':
      return { ...state, originInfo: action.originInfo };
    case 'SET_ORIGINS':
      return { ...state, origins: action.origins };
    default:
      return state;
  }
};

export default origins;
