import data from './data';

export const countryCodes = Object.keys(data);

export const list = Object.values(data);

export const getCountry = (countryCode) => {
  if (countryCode === undefined) {
    return list;
  }

  if (typeof countryCode !== 'string') {
    return undefined;
  }

  const code = countryCode.toUpperCase();

  return Object.prototype.hasOwnProperty.call(data, code)
    ? data[code]
    : undefined;
};
