import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  Box, Button, Container, List, ListItem, Paper, Typography,
} from '@mui/material';
import { setIngredientInfo } from '../actions';
import { fetchIngredientSearchResults } from '../lib/api';
import { deDupe, redirectAndScroll } from '../lib/utils';
import { paperStyle } from '../styles';

function IngredientSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');
  const ingredientInfo = useSelector((state) => state.ingredients.ingredientInfo);

  const doRedirect = (e, url) => {
    e.preventDefault();
    redirectAndScroll(navigate, url);
  };

  const doSearch = async (page = 1) => {
    const payload = await fetchIngredientSearchResults(query, page);
    // dedupe and sort results
    payload.ingredients = payload.ingredients.map((ing) => {
      const newIng = ing;
      newIng.dishes = deDupe(ing.dishes);
      newIng.dishes.sort((a, b) => a.name.localeCompare(b.name));
      return ing;
    });
    dispatch(setIngredientInfo(payload));
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <Container>
      <Typography variant="h2">
        Ingredient Search
      </Typography>
      <List>
        {ingredientInfo?.ingredients?.map((ing) => (
          <Paper key={`search-result-ing-${ing.id}`} style={paperStyle}>
            <ListItem key={`ingredient-list-item-${ing.id}`}>
              <Box>
                <Typography variant="h3">
                  {ing.name}
                </Typography>
              </Box>
              <Box>

                <List>
                  {ing?.dishes?.map((dish) => (
                    <li key={`ingredient-${ing.id}-dishes-list-item-${dish.id}`}>
                      <Button type="text" className="no-button" onClick={(e) => doRedirect(e, `/dish/${dish.id}`)}>
                        {dish.name}
                      </Button>
                    </li>
                  ))}
                </List>
              </Box>
            </ListItem>
          </Paper>
        ))}
      </List>
    </Container>

  );
}

export default IngredientSearch;
