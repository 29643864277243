import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { fetchSingleDish } from '../lib/api';
import { setActiveDish } from '../actions';
import Dish from './Dish';

function SingleDish() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dish = useSelector((state) => state.dishes.activeDish);

  const getSingleDish = async () => {
    const payload = await fetchSingleDish(id);
    dispatch(setActiveDish(payload.dish));
  };

  useEffect(() => {
    getSingleDish();
  }, []);

  return (
    <Container>
      <Dish dish={dish} />
    </Container>

  );
}

export default SingleDish;
