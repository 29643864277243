import React, { useEffect } from 'react';

import { HashRouter, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';

import { setUser } from './actions';
import Dishes from './components/Dishes';
import Origin from './components/Origin';
import Origins from './components/Origins';
import DishSearch from './components/DishSearch';
import IngredientSearch from './components/IngredientSearch';
import NavBar from './components/NavBar';
import SingleDish from './components/SingleDish';
import Ingredient from './components/Ingredient';
import CreateDish from './components/CreateDish';
import CreateOrigin from './components/CreateOrigin';
import EditDish from './components/EditDish';
import { authenticate } from './lib/authApi';
import ToTopButton from './components/ToTopButton';
import MadeDish from './components/MadeDish';
// import Footer from './components/Footer';
// import BrowseBar from './components/BrowseBar';

function App() {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['token']);

  const doAuth = async (token) => {
    const authResult = await authenticate(token);
    if (authResult.data) {
      dispatch(setUser(authResult.data));
    }
  };

  useEffect(() => {
    if (cookies.jwt) {
      doAuth(cookies.jwt);
    }
  }, []);

  return (
    <HashRouter>
      <NavBar />
      <Routes>
        <Route path="origin/:id" element={<Origin />} />
        <Route path="origin/create" element={<CreateOrigin />} />
        <Route path="origins" element={<Origins />} />
        <Route path="dish/:id" element={<SingleDish />} />
        <Route path="dish/edit/:id" element={<EditDish />} />
        <Route path="dish/create" element={<CreateDish />} />
        <Route path="dish/made" element={<MadeDish />} />
        <Route path="ingredients/:id" element={<Ingredient />} />
        <Route path="search/dish" element={<DishSearch />} />
        <Route path="search/ingredient" element={<IngredientSearch />} />
        <Route index path="/" element={<Dishes />} />
      </Routes>
      <ToTopButton />
    </HashRouter>

  );
}

export default App;
