import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { getCountry } from '../lib/countries';

function FlagEmojiButton(props) {
  const { action, origin } = props;
  return (
    <Button variant="text" key={`origin-${origin?.name}-${origin?.code}`} onClick={() => action(origin)} sx={{ fontSize: '1em', marginBottom: '1%' }}>
      {' '}
      { (getCountry(origin?.code))?.emoji}
      {' '}
    </Button>
  );
}

FlagEmojiButton.propTypes = {
  action: PropTypes.func.isRequired,
  origin: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    origin_type: PropTypes.string,
  }),
};
FlagEmojiButton.defaultProps = {
  origin: PropTypes.shape({
    id: 1,
    code: '',
    name: '',
    origin_type: '',
  }),
};
export default FlagEmojiButton;
