import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import {
  Box, Button, Container, List, ListItem, Paper, Typography,
} from '@mui/material';
import { setCurrentIngredient } from '../actions';
import { fetchSingleIngredient } from '../lib/api';
import { deDupe, redirectAndScroll } from '../lib/utils';
import { paperStyle } from '../styles';
// import Dish from './Dish';

function Ingredient() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const currentIngredient = useSelector((state) => state.ingredients.currentIngredient);

  const getIngredient = async () => {
    const payload = await fetchSingleIngredient(id);
    payload.ingredient.dishes = deDupe(payload.ingredient.dishes);
    payload.ingredient.dishes.sort((a, b) => a.name.localeCompare(b.name));
    dispatch(setCurrentIngredient(payload.ingredient));
  };

  useEffect(() => {
    getIngredient();
  }, []);

  const doRedirect = (e, url) => {
    e.preventDefault();
    redirectAndScroll(navigate, url);
  };

  return (
    <Container>
      <Typography variant="h2">{currentIngredient?.name}</Typography>
      <Paper style={paperStyle}>
        <Typography variant="h3">Dishes</Typography>
        <List>
          { currentIngredient?.dishes.map((dish) => (
            <ListItem key={`dish-${dish.id}-${dish.name}`}>

              <Button variant="text" className="no-button" onClick={(e) => doRedirect(e, `/dish/${dish.id}`)}>
                {dish.name}
              </Button>
            </ListItem>
            // <Dish dish={dish} key={`dish-${dish.id}-${dish.name}`} />
          ))}
        </List>
      </Paper>
      { user?.admin && (
      <Box>
        <h3>Debug only</h3>
        <ul>
          {currentIngredient?.dish_ingredients.map((di) => (
            <li key={`dish-ingredient-${di.id}`}>
              {di.description}
            </li>
          ))}
        </ul>
      </Box>
      )}
    </Container>

  );
}

export default Ingredient;
