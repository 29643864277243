/* eslint-disable no-case-declarations */
const ingredients = (
  state = {
    currentIngredient: {
      name: '',
      dishes: [],
      dish_ingredients: [],
    },
    ingredientInfo: {
      dishes: [],
      total: 0,
      maxPageNum: 0,
      perPage: 25,
    },
    currentPage: 1,
  },
  action = {},
) => {
  switch (action.type) {
    case 'SET_CURRENT_INGREDIENT':
      return { ...state, currentIngredient: action.currentIngredient };
    case 'SET_INGREDIENT_INFO':
      return { ...state, ingredientInfo: action.ingredientInfo };
    case 'SET_CURRENT_INGREDIENTS_PAGE':
      return { ...state, currentPage: action.currentPage };
    default:
      return state;
  }
};

export default ingredients;
