/* eslint-disable no-case-declarations */
const dishes = (
  state = {
    dishInfo: {
      dishes: [],
      total: 0,
      maxPageNum: 0,
      perPage: 25,
    },
    currentPage: 1,
    activeDish: {
      name: '',
      source: 'abc',
      steps: '',
    },
    showIngredients: true,
    showSteps: true,
    dishList: [],
  },
  action = {},
) => {
  switch (action.type) {
    case 'SET_DISH_INFO':
      return { ...state, dishInfo: action.dishInfo };
    case 'SET_CURRENT_DISHES_PAGE':
      return { ...state, currentPage: action.currentPage };
    case 'SET_ACTIVE_DISH':
      return { ...state, activeDish: action.activeDish };
    case 'SET_SHOW_INGREDIENTS':
      return { ...state, showIngredients: action.showIngredients };
    case 'SET_SHOW_STEPS':
      return { ...state, showSteps: action.showSteps };
    case 'SET_DISH_LIST':
      return { ...state, dishList: action.dishList };
    default:
      return state;
  }
};

export default dishes;
