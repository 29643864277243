import axios from 'axios';

const host = process.env.NODE_ENV === 'production' ? 'https://api.duggcook.net' : 'http://localhost:3001';
const authUrl = `${host}/user`;

const authOptions = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  mode: 'cors',
  credentials: 'include',
};

const authLogin = async (token) => {
  authOptions.headers.Authorization = `Bearer ${token}`;
  const instance = axios.create(authOptions);
  return instance.post(`${authUrl}/login`, {});
};

const authenticate = async (token) => {
  authOptions.headers.Authorization = `Bearer ${token}`;
  const instance = axios.create(authOptions);
  return instance.post(`${authUrl}/auth`, {});
};

export { authLogin, authenticate };
