import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import {
  Box, Divider, Drawer, List, ListItem, ListItemButton,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { redirectAndScroll } from '../lib/utils';
import { setDishInfo, setCurrentDishesPage } from '../actions';
import { fetchDishInfo } from '../lib/api';
import PageControls from './PageControls';

function BrowseBar(props) {
  const { open, setShowLoginModal, toggleDrawer } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , removeCookie] = useCookies(['cookie-name']);
  const user = useSelector((state) => state.users.user);
  const dishes = useSelector((state) => state.dishes.dishInfo.dishes);

  const doRedirect = (e, url) => {
    e.preventDefault();
    toggleDrawer();
    redirectAndScroll(navigate, url);
  };

  const doDishRedirect = (e, url) => {
    dispatch(setDishInfo({
      dishes: [],
      total: 0,
      maxPageNum: 0,
      perPage: 25,
    }));
    e.preventDefault();
    toggleDrawer();
    redirectAndScroll(navigate, url);
  };

  const logOut = () => {
    removeCookie('jwt', { path: '/' });
    window.location = '/';
  };

  const retrieveDishes = async (page = 1) => {
    const payload = await fetchDishInfo(page);
    dispatch(setDishInfo(payload));
  };

  const setPage = async (page) => {
    dispatch(setCurrentDishesPage(page));
    redirectAndScroll(navigate, `?page=${page}`);
    await retrieveDishes(page);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
    >
      <Box>
        <List>
          <ListItem><ListItemButton onClick={(e) => { doRedirect(e, '/'); }}>Home</ListItemButton></ListItem>
          <ListItem><ListItemButton onClick={(e) => { doRedirect(e, '/origins'); }}>Origins</ListItemButton></ListItem>
          <ListItem><ListItemButton onClick={(e) => { doRedirect(e, '/dish/made'); }}>Made</ListItemButton></ListItem>
          { user?.admin && <ListItem><ListItemButton onClick={(e) => { doRedirect(e, '/dish/create'); }}>Create Dish</ListItemButton></ListItem>}
          { user?.admin && <ListItem><ListItemButton onClick={(e) => { doRedirect(e, '/origin/create'); }}>Create Origin</ListItemButton></ListItem>}
          <ListItem>
            { user?.id
              ? <ListItemButton onClick={() => { logOut(); }}>Log Out</ListItemButton>
              : (
                <ListItemButton
                  onClick={() => { setShowLoginModal(true); }}
                >
                  Log In
                </ListItemButton>
              )}
          </ListItem>
        </List>
        <Divider style={{ marginBottom: '5%' }} />
        { dishes.length > 0 && (
        <Box style={{ marginBottom: '5%' }}>
          <PageControls
            setPage={setPage}
          />
          <List>
            {dishes.map((dish) => (
              <ListItem key={`browsebar-dish-list-id-${dish.id}`}>
                <ListItemButton onClick={(e) => doDishRedirect(e, `/dish/${dish.id}`)}>{dish.name}</ListItemButton>
              </ListItem>
            ))}
          </List>
          <PageControls
            setPage={setPage}
          />
        </Box>
        )}
      </Box>
    </Drawer>
  );
}

BrowseBar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  setShowLoginModal: PropTypes.func.isRequired,
};

export default BrowseBar;
