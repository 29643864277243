import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { configureStore } from '@reduxjs/toolkit';
import { theme } from './styles';
import reportWebVitals from './reportWebVitals';
import rootReducer from './reducers';
import App from './App';
import './index.css';

const store = configureStore({ reducer: rootReducer });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <GoogleOAuthProvider clientId="547143412944-d5munu20lbptlh5474a12pc497db4m5u">
          <Provider store={store}>
            <App />
          </Provider>
        </GoogleOAuthProvider>
      </CookiesProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
