import {
  Button,
  Container, List, ListItem, Paper, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setOrigins } from '../actions';
import { fetchOrigins } from '../lib/api';
import { getCountry } from '../lib/countries';
import { redirectAndScroll } from '../lib/utils';

function Origins() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const origins = useSelector((state) => state.origins.origins.origins);

  const getOrigins = async () => {
    const payload = await fetchOrigins();
    dispatch(setOrigins(payload));
  };

  useEffect(() => {
    getOrigins();
  }, []);

  const doRedirect = (e, url) => {
    e.preventDefault();
    redirectAndScroll(navigate, url);
  };

  return (
    <Container>
      <Typography variant="h2">Origins</Typography>
      <Paper>
        <List>
          { origins?.map((origin) => (
            <ListItem key={`dish-${origin.id}-${origin.name}`}>
              {/* TODO fix style here */}
              <Button variant="text" onClick={(e) => doRedirect(e, `/origin/${origin.id}`)}>
                {origin.name}
                {' '}
                { (getCountry(origin?.code))?.emoji}
              </Button>
            </ListItem>
            // <Dish dish={dish} key={`dish-${dish.id}-${dish.name}`} />
          ))}
        </List>
      </Paper>
    </Container>

  );
}

export default Origins;
