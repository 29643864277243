import React, { useState } from 'react';
import {
  Alert, Box,
  Button, Container, Paper, Snackbar, TextField, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { createOrigin } from '../lib/api';
import { getCountry } from '../lib/countries';
import { redirectAndScroll } from '../lib/utils';
import { formDividerStyle, paperStyle } from '../styles';
// import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
// import { setOriginInfo } from '../actions';
// import { fetchOriginInfo } from '../lib/api';
// import Dish from './Dish';

function CreateOrigin() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    // originType: 1,
  });

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const prepareFormForSubmission = async () => {
    const payload = {
      name: formData.name,
      code: formData.code,
      origin_type: 1,
    };
    try {
      const result = await createOrigin(payload);
      if (result?.id) {
        redirectAndScroll(navigate, `/origin/${result.id}`);
      }
    } catch (e) {
      setSnackMessage(e.message);
      setSnackOpen(true);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    prepareFormForSubmission();
  };

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  return (
    <Container>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">{snackMessage}</Alert>
      </Snackbar>
      <Typography variant="h2">Create Origin</Typography>
      <Paper style={paperStyle}>
        <Typography variant="h3">Origin</Typography>
        <Box style={formDividerStyle}>
          <TextField label="Name" onChange={(e) => handleOnChange(e)} value={formData.name} type="text" name="name" id="create-origin-name" />
        </Box>
        <Box style={formDividerStyle}>
          <TextField label="Code" onChange={(e) => handleOnChange(e)} value={formData.code} type="text" name="code" id="create-origin-code" />
          <Typography variant="emojiForm">
            { (getCountry(formData?.code))?.emoji}
          </Typography>
        </Box>
        <Box style={formDividerStyle}>
          <Button variant="contained" onClick={(e) => handleFormSubmit(e)}>Submit</Button>
        </Box>
      </Paper>
    </Container>

  );
}

export default CreateOrigin;
