import { combineReducers } from 'redux';
import dishes from './dishes';
import ingredients from './ingredients';
import origins from './origins';
import users from './users';

export default combineReducers({
  dishes,
  ingredients,
  origins,
  users,
});
