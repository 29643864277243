import { Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { setCurrentDishesPage, setDishInfo } from '../actions';
import { fetchDishSearchResults } from '../lib/api';
import { scrollToTop } from '../lib/utils';
import Dish from './Dish';
import PageControls from './PageControls';

function DishSearch() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');
  const dishInfo = useSelector((state) => state.dishes.dishInfo);
  const currentPage = useSelector((state) => state.dishes.currentPage);

  const doSearch = async (page = 1) => {
    const payload = await fetchDishSearchResults(query, page);
    dispatch(setDishInfo(payload));
  };

  const setPage = async (page) => {
    dispatch(setCurrentDishesPage(page));
    scrollToTop();
    await doSearch(page);
  };

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <Container>
      <Typography variant="h2">
        Dish Search
      </Typography>
      <PageControls
        dishInfo={dishInfo}
        currentPage={currentPage}
        setPage={setPage}
      />
      { dishInfo.dishes.map((dish) => (
        <Dish dish={dish} key={`dish-${dish.id}-${dish.name}`} />
      ))}
      <PageControls
        dishInfo={dishInfo}
        currentPage={currentPage}
        setPage={setPage}
      />
    </Container>

  );
}

export default DishSearch;
