import { createTheme } from '@mui/material/styles';

// TODO leave the theme below and make this theme.jsx

const theme = createTheme({
  palette: {
    primary: {
      main: '#32a852',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Roboto"',
            '"Oxygen"',
            '"Ubuntu"',
            '"Cantarell"',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            '"sans-serif"',
          ].join(''),
          margin: '1%',
        },
      },
    },
    // MuiList: {
    //   styleOverrides: {
    //     root: {
    //       // listStyle: 'inherit',
    //     },
    //   },
    // },
  },
  typography: {
    h1: {
      fontSize: '4rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    emojiForm: {
      fontSize: '2rem',
    },
  },
});

// TODO move this out
const paperStyle = {
  margin: '4% 0',
  padding: '2%',
};

const footerStyle = {
  maxWidth: '100%',
  backgroundColor: theme.palette.primary.main,
  margin: '3% 0 0 0',
  padding: '2%',
  marginRight: 0,
  color: 'white',
  textAlign: 'center',
};

const formDividerStyle = {
  padding: '1%',
};

const formControlLabelStyle = {
  marginLeft: '2%',
  minWidth: '35%',
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const toTop = {
  position: 'fixed',
  bottom: '1%',
  right: '2%',
  backgroundColor: 'white',
};

export {
  theme,
  footerStyle,
  formControlLabelStyle,
  formDividerStyle,
  modalStyle,
  paperStyle,
  toTop,
};
