import React from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { authLogin } from '../lib/authApi';
import { setUser } from '../actions';

function Login(props) {
  const { modalClose } = props;
  const dispatch = useDispatch();
  const [, setCookie] = useCookies(['token']);
  const handleResponse = async (response) => {
    const user = await authLogin(response.credential);
    dispatch(setUser(user.data.user));
    setCookie('jwt', user.data.token, { path: '/' });
    modalClose();
  };

  return (
    <GoogleLogin
      onSuccess={(response) => handleResponse(response)}
      // TODO
      onError={() => null}
    />
  );
}

Login.propTypes = {
  modalClose: PropTypes.func.isRequired,
};

export default Login;
