import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchDishInfo } from '../lib/api';
import { setCurrentDishesPage, setDishInfo } from '../actions';
import PageControls from './PageControls';
import Dish from './Dish';
import { redirectAndScroll } from '../lib/utils';
import HideShow from './HideShow';

function Dishes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pageNum = parseInt(searchParams.get('page'), 10) || 1;
  const dishInfo = useSelector((state) => state.dishes.dishInfo);

  const retrieveDishes = async (page = 1) => {
    const payload = await fetchDishInfo(page);
    dispatch(setDishInfo(payload));
  };

  const setPage = async (page) => {
    dispatch(setCurrentDishesPage(page));
    redirectAndScroll(navigate, `?page=${page}`);
    await retrieveDishes(page);
  };

  useEffect(() => {
    dispatch(setCurrentDishesPage(pageNum));
    retrieveDishes(pageNum);
  }, []);

  return (
    <Container>
      <HideShow />
      <PageControls
        setPage={setPage}
      />
      <Typography variant="h2">
        Dishes
      </Typography>
      { dishInfo.dishes.map((dish) => (

        <Dish dish={dish} key={`dish-${dish.id}-${dish.name}`} />
      ))}
      <PageControls
        setPage={setPage}
      />
    </Container>
  );
}

export default Dishes;
