/* eslint-disable camelcase */
import axios from 'axios';

const host = process.env.NODE_ENV === 'production' ? 'https://api.duggcook.net' : 'http://localhost:3001';
// const baseUrl = 'http:///api';
const baseUrl = `${host}/api`;

const options = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  mode: 'cors',
  credentials: 'include',
};

const instance = axios.create(options);

const doPaginatedResults = (result, resource) => {
  const perPage = parseInt(result.headers['per-page'], 10);
  const total = parseInt(result.headers.total, 10);
  const urls = result.headers.link?.split(',') || 1;
  let url;
  let maxPageNum;
  // const url = ''
  for (let i = 0; i < urls.length; i += 1) {
    const element = urls[i];
    if (element.includes('rel="last"')) {
      url = element.split(';')[0].slice(2);
    }
  }

  if (url) {
    maxPageNum = (new URL(url)).searchParams.get('page');
    maxPageNum = parseInt(maxPageNum, 10);
  } else {
    maxPageNum = Math.ceil(total / perPage);
  }
  const final = {
    maxPageNum, total, perPage,
  };
  final[resource] = result.data;
  return final;
};

const fetchDishInfo = async (page) => {
  try {
    const result = await instance.get(`${baseUrl}/dishes?page=${page || 1}`);
    return doPaginatedResults(result, 'dishes');
  } catch (e) {
    return {
      dishes: [],
      maxPageNum: 1,
      total: 1,
      perPage: 1,
    };
  }
};

const fetchOriginInfo = async (id) => {
  const result = await instance.get(`${baseUrl}/origins/${id}`);
  return {
    origins: result.data,
    maxPageNum: 1,
    total: 1,
    perPage: 1,
  };
};

const fetchSingleDish = async (id) => {
  const result = await instance.get(`${baseUrl}/dishes/${id}`);
  return {
    dish: result.data,
  };
};

const fetchMadeDishes = async () => {
  const result = await instance.get(`${baseUrl}/dishes/scope/made`);
  return {
    dish: result.data,
  };
};

const fetchNotMadeDishes = async () => {
  const result = await instance.get(`${baseUrl}/dishes/scope/not_made`);
  return {
    dish: result.data,
  };
};

const fetchOrigins = async (data) => {
  const result = await instance.get(`${baseUrl}/origins`, data);
  return doPaginatedResults(result, 'origins');
};

const fetchSingleIngredient = async (id) => {
  const result = await instance.get(`${baseUrl}/ingredients/${id}`);
  return {
    ingredient: result.data,
  };
};

const fetchDishSearchResults = async (query, page) => {
  const result = await instance.get(`${baseUrl}/search?q=${query}&page=${page}`);
  return doPaginatedResults(result, 'dishes');
};

const fetchOriginSearchResults = async (query, page = 1) => {
  const result = await instance.get(`${baseUrl}/search?q=${query}&page=${page}&t=origin`);
  return doPaginatedResults(result, 'origins');
};

const fetchIngredientSearchResults = async (query, page) => {
  const result = await instance.get(`${baseUrl}/search?q=${query}&page=${page}&t=ingredient`);
  return doPaginatedResults(result, 'ingredients');
};

const fetchIngredients = async (page) => {
  const result = await instance.get(`${baseUrl}/ingredients?page=${page || 1}`);
  return result.data;
};

const createDish = async (data) => {
  const result = await instance.post(`${baseUrl}/dishes`, data);
  return result.data;
};

const updateDish = async (id, data) => {
  const result = await instance.put(`${baseUrl}/dishes/${id}`, data);
  return result.data;
};

const deleteDish = async (id) => {
  const result = await instance.delete(`${baseUrl}/dishes/${id}`);
  return result;
};

const createOrigin = async (data) => {
  const result = await instance.post(`${baseUrl}/origins`, data);
  return result.data;
};

export {
  fetchDishInfo,
  fetchIngredients,
  fetchOriginInfo,
  fetchOrigins,
  fetchDishSearchResults,
  fetchIngredientSearchResults,
  fetchOriginSearchResults,
  fetchSingleDish,
  fetchMadeDishes,
  fetchNotMadeDishes,
  fetchSingleIngredient,
  createDish,
  updateDish,
  deleteDish,
  createOrigin,
};
